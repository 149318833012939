import * as Sentry from '@sentry/vue'

const config = useRuntimeConfig()
if (config.public.environment === 'production') {
  Sentry.init({
    dsn: 'https://687d348ac88672b75ba5028c68770451@o4506154787405824.ingest.us.sentry.io/4508091061567488',
    // Tracing
    tracesSampleRate: 1.0,
    ignoreErrors: ['Not Found', 'NetworkError'],
    beforeSend(event) {
      if (window.__NUXT__?.cdnGenerateCrawler === true) {
        return null // CDNのクローラーからのエラーは報告しない
      }
      return event
    },
  })
}
